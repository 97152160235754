import { NextApiRequest } from 'next'
import { NextRequest } from 'next/server'

const stripProtocolAndPort = (origin: string): string => {
  return origin.replace('https://', '').replace('http://', '').split(':')[0]
}

const stripProtocol = (origin: string): string => {
  return origin.replace('https://', '').replace('http://', '')
}

export const getSubdomain = (request: NextApiRequest | NextRequest): string | null => {
  const origin = request instanceof Request ? request.headers.get('origin') : request.headers?.origin
  const host = request instanceof Request ? request.headers.get('host') : request.headers?.host

  const strippedOrigin = stripProtocolAndPort(origin ?? host ?? '')

  return getSubdomainFromOrigin(strippedOrigin)
}

export const getSubdomainFromOrigin = (origin: string): string | null => {
  const appDomain = stripProtocolAndPort(process.env.NEXT_PUBLIC_DOMAIN || '')
  const appDomainWithPort = stripProtocol(process.env.NEXT_PUBLIC_DOMAIN || '')

  if (!origin || !appDomain) return null

  const subdomain = origin.replace(appDomainWithPort, '').replace(appDomain, '').replace('.', '')

  if (origin === subdomain || !subdomain) {
    return null
  }

  return subdomain
}
