'use client'

import React from 'react'
import Link from 'next/link'
import useNavbarType, { NavbarType } from '~/hooks/use-navbar-type'
import { useSubdomainRouter } from '~/hooks/use-subdomain-router'
import useBreakpoint from '~/hooks/useBreakpoint'
import { Divider } from '~/scalis-components/core/divider'
import SubdomainLink from '~/scalis-components/core/subdomain-link/subdomain-link'
import { Caption, CaptionColor } from '~/scalis-components/core/typography'
import { Routes } from '~/src/app/_constants/routes'
import { useUserContext } from '~/src/providers/user-provider'
import { Company } from '~/types/auth/TokenPayload'
import { cn } from '~/utils/cn'
import { AccountDropdownMenuProps } from '../account-dropdown-menu.types'
import { AccountAvatar } from './account-button'

export const AccountDropdownMenuContent: React.FC<AccountDropdownMenuProps> = ({ imageSrc, name, email }) => {
  const navbarType = useNavbarType()
  const { push } = useSubdomainRouter()
  const { companies } = useUserContext()
  const { lg } = useBreakpoint()

  const handleWorkspaceChange = async (e: React.MouseEvent<HTMLAnchorElement>, company: Company) => {
    e.preventDefault()
    push('/company/home/active', company.subdomain)
  }

  return (
    <>
      <div
        className={cn('flex gap-2 p-4', {
          'gap-3': !lg,
        })}
      >
        <AccountAvatar
          name={name}
          imageSrc={imageSrc}
          avatarClassName={cn('h-10 w-10', {
            'h-14 w-14 items-base': !lg,
          })}
        />
        <div className='flex flex-col'>
          <span className='text-base/6 font-medium text-neutral-primary'>{name}</span>
          <Caption color={CaptionColor.secondary}>{email}</Caption>
          <SubdomainLink
            subdomain=''
            href={Routes.user.profile}
            className='mt-1 text-sm/6 font-normal text-brand-primary-rest underline'
          >
            SCALIS Profile
          </SubdomainLink>
        </div>
      </div>
      <Divider />
      <div className='flex flex-col gap-2 p-4'>
        <h1 className='text-sm uppercase leading-4 text-neutral-secondary'>Account</h1>
        <div className='flex items-center gap-2 text-sm font-normal text-neutral-primary'>
          <i className='fa-regular fa-user' />
          <SubdomainLink
            subdomain=''
            href={Routes.user.settings.account_information}
            className='hover:underline'
          >
            User Settings
          </SubdomainLink>
        </div>
        <div className='flex items-center gap-2 text-sm font-normal text-neutral-primary'>
          <i className='fa-regular fa-lock-keyhole' />
          <SubdomainLink
            subdomain=''
            href={Routes.user.settings.sign_in_and_security}
            className='hover:underline'
          >
            Sign In & Security
          </SubdomainLink>
        </div>
      </div>

      {navbarType !== NavbarType.COMPANY_WORKSPACE && !!companies?.length ? (
        <>
          <Divider />
          <div className='flex flex-col gap-2 p-4'>
            <h1 className='text-sm uppercase leading-4 text-neutral-secondary'>Company Workspace</h1>
            {companies.map((company) => (
              <button
                key={company.id}
                onClick={(e: any) => handleWorkspaceChange(e, company)}
                className='text-sm font-normal text-neutral-primary hover:underline text-start'
              >
                {company.subdomain}
                <span className='text-neutral-secondary'>{` (${company.id})`}</span>
              </button>
            ))}
            {process.env.NEXT_PUBLIC_ENV === 'local' && (
              <Link
                className='text-sm font-normal text-neutral-primary hover:underline text-start'
                href={Routes.workspace.create}
              >
                Create Workspace [DEV ONLY]
              </Link>
            )}
          </div>
        </>
      ) : (
        <>
          {process.env.NEXT_PUBLIC_ENV === 'local' && (
            <>
              <Divider />
              <div className='flex flex-col gap-2 p-4'>
                <SubdomainLink
                  className='text-sm font-normal text-neutral-primary hover:underline text-start'
                  subdomain=''
                  href={Routes.workspace.create}
                >
                  Create Workspace [DEV ONLY]
                </SubdomainLink>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
