import { useEffect, useState } from 'react'
import Link from 'next/link'
import { getSubdomainUrl } from '~/hooks/use-subdomain-router'

type Props = {
  subdomain: string
  href: string
  children?: React.ReactNode
  onClick?: (e: any) => void
  className?: string
}

const SubdomainLink = ({ subdomain, href, children, onClick, className }: Props) => {
  const [subdomainUrl, setSubdomainUrl] = useState('')

  useEffect(() => {
    setSubdomainUrl(getSubdomainUrl(href, subdomain))
  }, [subdomain, href])

  return (
    <Link
      href={subdomainUrl}
      onClick={onClick}
      className={className}
    >
      {children}
    </Link>
  )
}

export default SubdomainLink
