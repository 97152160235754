'use client'

import { Avatar, AvatarItemAlignmentEnum } from '~/scalis-components/core/avatar'
import { AVATAR_GROUP_COLORS_ARRAY } from '~/scalis-components/core/avatar/avatar-group/avatar-group.constants'
import { getHexColor } from '~/scalis-components/core/avatar/avatar-group/avatar-group.utils'
import { AccountButtonProps } from '../../account-button.types'

export const AccountAvatar = ({
  imageSrc = '',
  name,
  avatarClassName,
  backgroundColor,
}: Partial<AccountButtonProps>) => {
  const nameLength = (name?.length ?? 0) % AVATAR_GROUP_COLORS_ARRAY.length

  return (
    <Avatar
      src={imageSrc}
      fullName={name}
      backgroundColor={backgroundColor ?? getHexColor(nameLength, AVATAR_GROUP_COLORS_ARRAY)}
      className={avatarClassName}
      verticalAlignment={AvatarItemAlignmentEnum.Base}
    />
  )
}
