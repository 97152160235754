'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Routes } from 'src/app/_constants/routes'
import { Button } from '@scalis/components/core/button'
import { Drawer } from '@scalis/components/core/drawer'
import { CompanyAvatar } from '~/scalis-components/core/company-avatar'
import { Divider } from '~/scalis-components/core/divider'
import { AccountDropdownMenu } from '~/scalis-components/core/navbar/account-dropdown-menu/account-dropdown-menu'
import { AccountDropdownMenuFooter } from '~/scalis-components/core/navbar/account-dropdown-menu/components/footer'
import SubdomainLink from '~/scalis-components/core/subdomain-link/subdomain-link'
import { useNavLinks } from '~/scalis-components/team/navbar-variations/default/navbar-default.hooks'
import { useUserContext } from '~/src/providers/user-provider'
import { getFullName } from '~/utils/get-full-name'
import { getUser } from '~/v2/helpers/workspace-helper'
import { NAV_BTN_CONFIG } from '../navbar/navbar.constants'
import { LoginButton } from '../shared/LoginButton'
import { Logo } from '../shared/Logo'

interface NavDrawerProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

const NavigationLinks = ({ onNavigate }: { onNavigate: () => void }) => {
  const navLinks = useNavLinks()
  const pathname = usePathname()

  return (
    <div className='flex flex-col gap-4'>
      {navLinks
        .filter((link) => link.href !== pathname)
        .map(({ label, href }, index) => (
          <Link
            role='link'
            aria-label={label}
            href={href}
            key={`navbar-link-index-${index}`}
            onClick={onNavigate}
          >
            {label}
          </Link>
        ))}
    </div>
  )
}

const GuestActions = () => (
  <div className='my-4 grid grid-cols-2 gap-2'>
    <LoginButton />
    <Button
      {...NAV_BTN_CONFIG.scheduleDemo}
      className='whitespace-nowrap'
    />
  </div>
)

export const NavDrawer = ({ isOpen, onOpenChange }: NavDrawerProps) => {
  const { user, isSignedIn, currentWorkspace, subdomain } = useUserContext()
  const info = currentWorkspace?.currentCompany

  const handleNavigate = () => {
    onOpenChange(false)
  }

  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title=''
      hideFooterContent
      contentClassName='p-0'
    >
      <div className='flex flex-col p-4'>
        <nav className='flex flex-col'>
          <div className='mb-6'>
            {info ? (
              <SubdomainLink
                subdomain={subdomain ?? ''}
                href={Routes.jobs_view}
              >
                <CompanyAvatar
                  size='md'
                  imageSrc={info.imageSrc ?? ''}
                  name={info.name ?? subdomain}
                />
              </SubdomainLink>
            ) : (
              <Logo />
            )}
          </div>
          <div className='flex flex-1 flex-col'>
            <NavigationLinks onNavigate={handleNavigate} />
            {!isSignedIn && <GuestActions />}
          </div>
        </nav>
      </div>

      <div className='h-px w-full bg-gray-200' />
      {isSignedIn && (
        <>
          {user && <UserProfile user={user} />}
          <Divider />
        </>
      )}

      <div className='p-4'>
        <div className='flex flex-col gap-4 text-sm text-neutral-secondary'>
          <Link href={`${process.env.NEXT_PUBLIC_APP_URL}${Routes.landing.legal.termsOfService}`}>
            Terms and Conditions
          </Link>
          {!isSignedIn && (
            <Link href={`${process.env.NEXT_PUBLIC_APP_URL}${Routes.landing.legal.privacyPolicy}`}>Privacy Policy</Link>
          )}
          <Link href={`${process.env.NEXT_PUBLIC_APP_URL}${Routes.landing.legal.dpa}`}>
            Data Processing Agreement (DPA)
          </Link>
        </div>
      </div>

      <Divider />
      {isSignedIn && (
        <>
          <AccountDropdownMenuFooter />
        </>
      )}
    </Drawer>
  )
}

export const UserProfile = ({ user }: { user: Awaited<ReturnType<typeof getUser>> }) => {
  if (!user) return null

  return (
    <div className='flex items-center justify-between p-4'>
      <AccountDropdownMenu
        email={user?.EmailVerification[0]?.emailAddress ?? ''}
        imageSrc={user?.profileImage ?? ''}
        name={getFullName(user?.firstName, user?.lastName) ?? ''}
      />
      <div className='ml-2 flex-1'>{getFullName(user?.firstName, user?.lastName) ?? ''}</div>
    </div>
  )
}
