import { useRouter } from 'next/navigation'
import { getSubdomainFromOrigin } from '~/utils/auth/get-subdomain'

export const getSubdomainUrl = (path: string, subdomain: string | null) => {
  // Use a consistent approach for both server and client
  const protocol = typeof window !== 'undefined' ? window.location.protocol : 'http:'
  const baseDomain = process.env.NEXT_PUBLIC_DOMAIN || 'scalis.loc:3000'

  // For localhost, just return the path
  if (typeof window !== 'undefined' && window.location.host.includes('localhost')) {
    return path
  }

  // Consistent URL generation for both server and client
  if (!subdomain) return `${protocol}//${baseDomain}${path}`
  return `${protocol}//${subdomain}.${baseDomain}${path}`
}

export const useSubdomainRouter = () => {
  const router = useRouter()

  const push = (path: string, newSubdomain: string) => {
    const urlOrPath = getSubdomainUrl(path, newSubdomain)

    const oldSubdomain = getSubdomainFromOrigin(window.location.host)

    if (oldSubdomain !== newSubdomain) {
      window.location.href = urlOrPath
    } else {
      router.push(path)
    }
  }

  return { ...router, push }
}
