import React from 'react'
import SubdomainLink from '~/scalis-components/core/subdomain-link/subdomain-link'
import { Routes } from '~/src/app/_constants/routes'
import { handleSignOut } from '~/utils/auth/handle-sign-out'

export const AccountDropdownMenuFooter = () => {
  return (
    <div className='flex w-full justify-between p-4'>
      <SubdomainLink
        className='flex cursor-pointer items-center gap-2 text-sm font-medium text-brand-primary-rest'
        href={`${process.env.NEXT_PUBLIC_APP_URL}${Routes.landing.legal.privacyPolicy}`}
        subdomain=''
      >
        Privacy Policy
      </SubdomainLink>
      <button
        type='button'
        className='flex cursor-pointer items-center gap-2 text-sm font-medium text-brand-primary-rest'
        onClick={() => handleSignOut()}
      >
        Log Out
        <i className='fa-regular fa-arrow-right-from-bracket' />
      </button>
    </div>
  )
}
